<template>
	<div class="content" :style="cssVars">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<div v-if="museum" class="container">
			<img class="bg-image" :src="museum.homeImageUrl" alt="">
		</div>
		
		<main v-if="!isLoading && museum">
			<router-link :to="{ name: 'Presentation', params: { idMuseum: encrypted } }" class="logo">
				<img class="logo-image" :src="museum.logoImageUrl" :alt="museum.locale ? museum.locale.name : ''">
			</router-link>
			
			<h1>{{ $t('goodbye') }}</h1>
			<p class="text">{{ $t('reset_explication') }}</p>
		
		</main>
		
		<footer>
			<div class="footer-buttons">
				<button class="button-white" @click="goBack()">
					<i class="mm-icon mm-icon-close big"/>
				</button>
				<button class="button-blue" @click="reset()">
					<i class="mm-icon mm-icon-arrow-right big"/>
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import {mapActions, mapState} from "vuex";
import Loading from "vue-loading-overlay";
import MuseumsAPI from "@/api/MuseumsAPI";
import {setFullScreen} from "@/store/helpers";

export default {
	name: "Reset",
	
	components: {
		Loading,
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			email: "",
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			museumError: state => state.app.museumFetchingError,
			visit: state => state.app.visit,
			visitv2: state => state.app.visitv2
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('goodbye');
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		cssVars() {
			return {
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		}
	},
	
	created () {
		debug.open("Reset");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseumPresentation(this.idMuseum)
				.finally(() => {
					debug.close();
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			debug.close();
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseumPresentation']),
		
		reset() {
			debug.open("reset");
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			this.$store.commit('app/' + mutationTypes.UPDATE_VISIT_END_DATE, new Date());
      if(this.isNetworkOnline){
        MuseumsAPI.registerVisit(this.idMuseum, {...this.visit, languageCode: this.visitv2?.languageCode || this.visit.languageCode})
            .then((response) => {
              this.$store.commit('app/' + mutationTypes.SET_VISIT, response.data.data, {root: true});
              localStorage.setItem('visit', JSON.stringify(response.data.data));
            })
            .catch((e) => {
              if (process.env.VUE_APP_DEBUG_MODE == "true") console.error(e);
              this.$store.commit('app/' + mutationTypes.SET_VISIT, this.visit, {root: true});
              localStorage.setItem('visit', JSON.stringify(this.visit));
            })
            .finally(async() => {
              this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
              debug.close();
              await this.$store.dispatch('app/resetMuseum');
              await this.$router.push({name: 'Presentation', params: {idMuseum: this.encrypted}});
            });
      }
		},
		
		goBack() {
			setFullScreen();
			this.$router.push({ name: 'Options', params: { idMuseum: this.encrypted } });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: calc(100% - 90px);
	
	.container {
		overflow: hidden;
		position: fixed;
		width: 100%;
		height: calc(100% - 90px);
		z-index: -1;
		
		.bg-image {
			width: 100%;
			height: 100%;
			filter: blur(20px);
			transform: scale(1.5);
		}
	}
	
	main {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			min-height: 35%;
			padding-top: 75px;
			
			.logo-image {
				width: 70%;
				height: auto;
			}
		}
		
		h1 {
			margin-top: 20px;
			color: white;
			font-weight: normal;
			font-size: 24px;
			position: relative;
			
			&::after {
				content: "";
				position: absolute;
				left: -15%;
				top: 50px;
				width: 130%;
				height: 1px;
				background-color: white;
			}
		}
		
		.text {
			margin-top: 50px;
			margin-bottom: 10px;
			text-align: center;
			color: white;
			padding: 0 20px;
		}
		
	}
	
	footer {
		position: fixed;
		bottom: 0;
		height: 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			min-height: 90px;
			display: flex;
			justify-content: center;
			position: absolute;
			top: 0;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				display: flex;
				justify-content: center;
				align-items: center;
				
				&.button-white {
					background-color: white;
					color: black;
				}
				
				&.button-blue {
					background-color: var(--main-buttons-color);
					color: var(--main-buttons-text-color);
					
					.mm-icon {
						font-size: 30px;
						color: var(--main-buttons-text-color);
					}
				}
			}
		}
	}
}
</style>
