<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<side-menu v-if="!isLoading"
		           v-show="showMenu"
		           :id-museum="encrypted"
		           @close-menu="showMenu = false"/>
		
		<nav v-if="!isLoading">
			<h1>{{ sideMenu && sideMenu.locale ? sideMenu.locale.outstandingArtworksText : $t('most_outstanding_artworks') }}</h1>
			<div v-if="museum.menuAvailable" class="menu" @click="showMenu = true; setFullScreen()">
				<img class="menu-icon" src="@/assets/icons/stayrelax_black.png" alt="">
			</div>
		</nav>
		
		<div v-if="!isLoading && outstandingArtworksError" class="error-code">
			{{ $t('no_artworks_category') }}
		</div>
		
		<main v-else-if="!isLoading && outstandingArtworks.length">
			<article v-for="artwork in outstandingArtworks" :key="artwork.idArtwork"
			         @click="goToArtwork(artwork.idArtwork)">
				<img class="artwork-image" :src="artwork.mainImageUrl" alt="">
				<div class="artwork-info">
					<h2>{{ artwork.author }}</h2>
					<h3>{{ artwork.locale.name }}</h3>
				</div>
			</article>
		</main>
		
		
		<footer v-if="!museum.menuAvailable">
			<button class="button-white" @click="goToOptions()">
				<i class="mm-icon mm-icon-arrow-back big"/>
			</button>
		</footer>
		
		<footer :style="footerCSSVars">
			<div class="footer-buttons">
				<button class="button-white" @click="goToOptions()">
					<i class="mm-icon mm-icon-arrow-back big"/>
				</button>
				<button v-if="museum.recognitionAvailable" class="button-recognition" @click="goToRecognition()">
					<img v-if="customization && customization.recognitionIconUrl" class="recognition-icon" :src="customization.recognitionIconUrl" alt="">
					<img v-else class="recognition-icon" src="@/assets/icons/pointplay.png" alt="">
				</button>
			</div>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import SideMenu from "@/components/SideMenu";
import {setFullScreen} from "@/store/helpers";

export default {
	name: "OutstandingArtworks",
	
	components: {
		SideMenu,
		Loading
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			showMenu: false,
			keyboardCustomization: null,
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			customization: state => state.museum.customization,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
			outstandingArtworks: state => state.artwork.outstandingArtworks,
			outstandingArtworksError: state => state.artwork.outstandingArtworksError,
			sideMenu: state => state.museum.sideMenu,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('most_outstanding_artworks');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
		
		footerCSSVars() {
			return {
				'--recognition-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.recognitionColor : '#f06140',
			};
		},
	},
	
	created() {
		debug.log("[OutstandingArtworks] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		this.$store.commit('app/' + mutationTypes.SET_VIEW_FROM, { name: 'OutstandingArtworks', params: { idMuseum: this.encrypted } });
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.then(() => {
					this.getOutstandingArtworks();
					if(this.museum.keyboardCustomization && this.museum.keyboardCustomization.active) {
						this.keyboardCustomization = this.museum.keyboardCustomization;
					}
				})
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		...mapActions('artwork', ['getOutstandingArtworks']),
		
		setFullScreen(to = null) {
			setFullScreen();
			if(to) {
				this.$router.push(to);
			}
		},
		
		goToRecognition() {
			setFullScreen();
			this.$router.push({name: 'Recognition', params: { idMuseum: this.encrypted} });
		},
		
		goToArtwork(idArtwork) {
			setFullScreen();
			this.$router.push({ name: 'Artwork', params: { idMuseum: this.encrypted, idArtwork: idArtwork } });
		},
		
		goToOptions() {
			setFullScreen();
			this.$router.push({ name: "Options", params: { idMuseum: this.encrypted } });
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	
	.error-code {
		padding: 80px 20px 100px 20px;
		background-color: white;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 22px;
	}
	
	nav {
		position: fixed;
		top: 0;
		height: 75px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		border-bottom: 1px solid #cfcfd0;
		z-index: 2;
		
		h1 {
			font-weight: normal;
			font-size: 22px;
			padding: 0 20px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
		
		.menu {
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #cfcfd0;
			padding: 5px 20px;
			
			.menu-icon {
				width: 40px;
				height: auto;
			}
		}
	}
	
	main {
		padding: 70px 0 90px;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: white;
		overflow-y: scroll;
		
		article {
			width: 100%;
			position: relative;
			margin-bottom: -3px;
			
			.artwork-image {
				width: 100%;
				height: 150px;
				object-fit: cover;
			}
			
			.artwork-info {
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 20px;
				display: flex;
				flex-direction: column;
				
				h2 {
					color: white;
					font-weight: normal;
					font-size: 18px;
				}
				
				h3 {
					color: white;
					font-weight: normal;
					font-size: 22px;
				}
			}
		}
	}
	
	footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		justify-items: center;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		
		.footer-buttons {
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;
			
			button {
				width: 100%;
				border: none;
				text-transform: uppercase;
				
				&.button-white {
					border: none;
					background-color: white;
					height: 100%;
					color: black;
					
					img {
						width: 15px;
						height: auto;
					}
				}
				
				&.button-recognition {
					color: white;
					background-color: var(--recognition-color);
					
					.recognition-icon {
						max-width: 60%;
						max-height: 80%;
					}
				}
			}
		}
	}
}
</style>
